"use client"; // Mark this component as a client component

import { useEffect, useState } from "react";
import fetchGraphQL from "@/helper/fetch";

function Hero() {
  const [hero, setHero] = useState({ title: "", subtitle: "" });

  // Fetch data on the client side
  useEffect(() => {
    async function fetchHeroData() {
      const QUERY = `query {
        sectionCollection {
          items {
            _id,
            heroTitle,
            heroSubtitle
          }
        }
      }`;

      try {
        const result = await fetchGraphQL(QUERY);
        console.log("section result=>", result.data);
        const data = result.data?.sectionCollection.items[0];
        setHero({
          title: data?.heroTitle || "Default Title",
          subtitle: data?.heroSubtitle || "",
        });
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    }

    fetchHeroData();
  }, []);

  return (
    <div className="hero flex items-center justify-start">
      <div className="hero__image">
        <img src="/temp/222.jpg" alt="hero" className="w-full" height={100} />
      </div>

      <div className="shell w-full">
        <div className="md:px-0 px-4 ">
          <div className="hero__inner">

            <h1 className="font-bold whitespace-wrap flex flex-col justify-start">
{/*               {hero.title.split(" ").map((x: any, index: number) => (
                <span key={index} className="lg:text-[80px] text-[40px]">
                  {x}
                </span>
              ))} */}
              Коледа е тук!
            </h1>
            
{/*             <p className="lg:text-[20px] text-[18px]">{hero.subtitle}</p> */}

            <a href="/discounts" className="btn">
              Коледни Намаления
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
